import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";
import Header3 from "../components/header/header3";
import MGCaption from "../components/MGCaption";
import NaviNext from "../components/NaviNext";
import { fInt, fFloat } from "../components/helper";

const Part4 = () => {
  return (
    <Tail>
      <Hero>
        <h1>
          РАЗДЕЛ 4. <br/>ОСНОВНЫЕ ПОКАЗАТЕЛИ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ
        </h1>
      </Hero>

      <TextBlock>
        <Header2>
          4.1. КЛЮЧЕВЫЕ ПОКАЗАТЕЛИ ЭФФЕКТИВНОСТИ И ВЫПОЛНЕНИЯ СТРАТЕГИЧЕСКОГО
          БИЗНЕС-ПЛАНА ОБЩЕСТВА
        </Header2>

        <p>
          Советом директоров Общества не утверждался стратегический бизнес-план
          и стратегические ключевые показатели эффективности деятельности
          Общества на 2021 год.
        </p>

        <Header2>
          4.2. КЛЮЧЕВЫЕ ПОКАЗАТЕЛИ ЭФФЕКТИВНОСТИ И ВЫПОЛНЕНИЯ ОПЕРАТИВНОГО
          БИЗНЕС-ПЛАНА ОБЩЕСТВА
        </Header2>

        <p>
          Оперативное планирование и контроль финансово-хозяйственной
          деятельности Общества осуществляются в соответствии с Положением об
          оперативном планировании финансово-хозяйственной деятельности АО
          «МОСГАЗ», разработанным в соответствии с постановлением Правительства
          Москвы от 03.07.2007 г. № 576-ПП и утвержденным Советом директоров
          Общества.
        </p>

        <p>
          Оперативные планы и основные бюджеты, а также плановые значения
          оперативных ключевых показателей эффективности (КПЭ) финансово-
          хозяйственной деятельности на 2021 год утверждены Советом директоров
          (Протокол заседания Совета директоров АО «МОСГАЗ» от 29.06.2021 №
          127).
        </p>

        <p>
          <strong>
            По итогам работы 2021 года Обществом выполнены оперативные планы и
            основные бюджеты Общества.
          </strong>
        </p>
        <p>
          <strong>
            Установленные Советом директоров значения основных оперативных
            ключевых показателей эффективности (КПЭ) достигнуты, причем большая
            часть существенно превышает плановые показатели.
          </strong>
        </p>

        <p>
          По трем важным КПЭ: выручка, рентабельность продаж и прибыль до
          налогообложения, фактически достигнутые значения КПЭ являются лучшими
          по сравнению с плановыми значениями КПЭ, скорректированными на
          величину сверхнормативного отклонения.
        </p>

        <MGCaption>
          Выполнение ключевых показателей эффективности в 2021 году
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td>Показатель</td>
              <td>
                Единица <br />
                измерения
              </td>
              <td>
                Плановое значение <br />
                показателя
              </td>
              <td>
                Фактическое значение <br />
                показателя
              </td>
              <td>Абсолютное отклонение</td>
              <td>
                Относительное отклонение, <br />%
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6}>
                <strong>Важные оперативные КПЭ</strong>
              </td>
            </tr>
            <tr>
              <td>Выручка</td>
              <td>тыс. руб.</td>
              <td>{fInt(14_012_988)}</td>
              <td>{fInt(17_621_284)}</td>
              <td>{fInt(3_608_296)}</td>
              <td>{fFloat(25.7)}</td>
            </tr>

            <tr>
              <td>Рентабельность продаж</td>
              <td>%</td>
              <td>{fFloat(17.5)}</td>
              <td>{fFloat(30.6)}</td>
              <td>{fFloat(13.2)}</td>
              <td>{fFloat(75.4)}</td>
            </tr>

            <tr>
              <td>Прибыль до налогообложения</td>
              <td>тыс. руб.</td>
              <td>{fInt(3_846_748)}</td>
              <td>{fInt(7_640_892)}</td>
              <td>{fInt(3_794_144)}</td>
              <td>{fFloat(98.6)}</td>
            </tr>

            <tr>
              <td colSpan={6}>
                <strong>Второстепенные оперативные КПЭ</strong>
              </td>
            </tr>

            <tr>
              <td>Коэффициент общей оборачиваемости активов</td>
              <td></td>
              <td>{fFloat(0.162, 3)}</td>
              <td>{fFloat(0.187, 3)}</td>
              <td>{fFloat(0.024, 3)}</td>
              <td>{fFloat(14.9, 1)}</td>
            </tr>

            <tr>
              <td>Остаточная стоимость внеоборотных активов</td>
              <td>тыс. руб.</td>
              <td>{fInt(73_449_670)}</td>
              <td>{fInt(70_966_094)}</td>
              <td>({fInt(2_483_576)})</td>
              <td>{fFloat(-3.4, 1)}</td>
            </tr>

            <tr>
              <td>
                Отсутствие нарушений условий транспортировки газа потребителю по
                вине АО «МОСГАЗ»
              </td>
              <td>качественный КПЭ</td>
              <td>да</td>
              <td>да</td>
              <td>—</td>
              <td>—</td>
            </tr>
          </tbody>
        </table>
        <p className="text-sm italic">
          <sup>*</sup> Значение второстепенного КПЭ «Остаточная стоимость
          внеоборотных активов» отклоняется от плана на уровень, не превышающий
          границы допустимого отклонения.
        </p>
        <p>
          При оценке эффективности финансово-хозяйственной деятельности,
          производимой согласно Методике определения эффективности к
          постановлению Правительства Москвы от 3 июля 2007 г. № 576-ПП,
          Обществу присвоена категория «НОРМАЛЬНАЯ», категория Генерального
          директора соответствует категории «А».
        </p>
        <p>
          В соответствии с применяемой кредитной политикой, рейтинг Общества по
          итогам 2021 года соответствует 1 классу – устойчивое финансовое
          состояние.
        </p>
        <p>
          Отчет о выполнении КПЭ и основных бюджетов Общества за 2021 год с
          пояснением причин существенных отклонений приводится в приложении к
          настоящему Годовому отчету.
        </p>

        <Header2>
          4.3. ОСНОВНЫЕ РЕЗУЛЬТАТЫ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ.
        </Header2>

        <div className="Factoid text-xl" todo="Это один или два фактойда?">
          Чистая прибыль 6060 млн руб. (+56%)<br />
          Прибыль к распределению – 845,8 млн руб.
        </div>

        <MGCaption>Финансовые результаты, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Выручка от реализации</td>
              <td>{fInt(13_947)}</td>
              <td>{fInt(14_395)}</td>
              <td>{fInt(17_621)}</td>
              <td>{fInt(22)}%</td>
            </tr>

            <tr>
              <td>Операционные расходы</td>
              <td>({fInt(11_547)})</td>
              <td>({fInt(11_485)})</td>
              <td>({fInt(12_231)})</td>
              <td>{fInt(6)}%</td>
            </tr>

            <tr className="grayLight grayAccent">
              <td>Прибыль (убыток) от продаж</td>
              <td>{fInt(2401)}</td>
              <td>{fInt(2911)}</td>
              <td>{fInt(5391)}</td>
              <td>{fInt(85)}%</td>
            </tr>

            <tr>
              <td>Проценты к получению</td>
              <td>{fInt(288)}</td>
              <td>{fInt(328)}</td>
              <td>{fInt(676)}</td>
              <td>{fInt(106)}%</td>
            </tr>

            <tr>
              <td>Прочие доходы</td>
              <td>{fInt(690)}</td>
              <td>{fInt(2164)}</td>
              <td>{fInt(2789)}</td>
              <td>{fInt(29)}%</td>
            </tr>

            <tr>
              <td>Прочие расходы</td>
              <td>({fInt(564)})</td>
              <td>({fInt(646)})</td>
              <td>({fInt(1214)})</td>
              <td>{fInt(88)}%</td>
            </tr>

            <tr className="grayLight grayAccent">
              <td>Прибыль (убыток) до налогообложения</td>
              <td>{fInt(2815)}</td>
              <td>{fInt(4757)}</td>
              <td>{fInt(7641)}</td>
              <td>{fInt(61)}%</td>
            </tr>

            <tr>
              <td>Налог на прибыль</td>
              <td>({fInt(568)})</td>
              <td>({fInt(874)})</td>
              <td>({fInt(1580)})</td>
              <td>{fInt(81)}%</td>
            </tr>

            <tr className="grayLight grayAccent">
              <td>Чистая прибыль (убыток) отчетного периода</td>
              <td>{fInt(2247)}</td>
              <td>{fInt(3883)}</td>
              <td>{fInt(6060)}</td>
              <td>{fInt(56)}%</td>
            </tr>

            <tr>
              <td>Инвестиционная составляющая чистой прибыли</td>
              <td>({fInt(2067)})</td>
              <td>({fInt(3415)})</td>
              <td>({fInt(4328)})</td>
              <td>{fInt(27)}%</td>
            </tr>

            <tr>
              <td>Неденежная составляющая чистой прибыли</td>
              <td>({fInt(390)})</td>
              <td>({fInt(1109)})</td>
              <td>({fInt(886)})</td>
              <td>({fInt(20)})%</td>
            </tr>

            <tr className="grayLight grayAccent">
              <td>Чистая прибыль к распределению</td>
              <td>({fInt(210)})</td>
              <td>({fInt(640)})</td>
              <td>{fInt(846)}</td>
              <td>{fInt(232)}%</td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>Финансовый результат к распределению</strong> – чистая прибыль
          (убыток) за вычетом инвестиционной состав- ляющей, направляемой на
          финансирование мероприятий по реконструкции газовых сетей и сооружений
          и осу- ществление технологических присоединений, и за вычетом прочих
          неденежных статей <strong>положительный – впервые с 2018 года</strong>
          . В предыдущие периоды деятельность Общества была убыточной.
        </p>

        <Header3>4.3.1. ВЫРУЧКА</Header3>
        <div className="Factoid text-xl mb-10">
          Выручка составила 17 621 млн руб. (+22%)
        </div>

        <MGCaption>Выручка, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
              <td>
                Доля в <br />
                выручке
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Транспортировка газа</td>
              <td>{fInt(10_858)}</td>
              <td>{fInt(10_639)}</td>
              <td>{fInt(14_330)}</td>
              <td>{fInt(35)}%</td>
              <td>{fInt(81)}%</td>
            </tr>

            <tr>
              <td>Обслуживание ВДГО</td>
              <td>{fInt(906)}</td>
              <td>{fInt(921)}</td>
              <td>{fInt(992)}</td>
              <td>{fInt(8)}%</td>
              <td>{fInt(6)}%</td>
            </tr>

            <tr>
              <td>
                Услуги по технологическому присоединению и{" "}
                <br todo="проверить на разных экранах" />
                выносу газопроводов
              </td>
              <td>{fInt(748)}</td>
              <td>{fInt(1675)}</td>
              <td>{fInt(635)}</td>
              <td>({fInt(62)})%</td>
              <td>{fInt(4)}%</td>
            </tr>

            <tr>
              <td>
                Капитальный ремонт внутридомовых систем <br />
                газоснабжения по ФКР
              </td>
              <td>{fInt(731)}</td>
              <td>{fInt(377)}</td>
              <td>{fInt(477)}</td>
              <td>{fInt(27)}%</td>
              <td>{fInt(3)}%</td>
            </tr>

            <tr>
              <td>СМР и ПИР для сторонних заказчиков </td>
              <td>{fInt(395)}</td>
              <td>{fInt(492)}</td>
              <td>{fInt(786)}</td>
              <td>{fInt(60)}%</td>
              <td>{fInt(4)}%</td>
            </tr>

            <tr>
              <td>Прочая деятельность</td>
              <td>{fInt(310)}</td>
              <td>{fInt(293)}</td>
              <td>{fInt(403)}</td>
              <td>{fInt(37)}%</td>
              <td>{fInt(2)}%</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Итого:</td>
              <td>{fInt(13_947)}</td>
              <td>{fInt(14_395)}</td>
              <td>{fInt(17_621)}</td>
              <td>{fInt(22)}%</td>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <p>
          Выручка по транспортировке газа – основные доходы Общества, доля в
          выручке составляет более 80%.
        </p>
        <p>
          <strong>
            Совокупная выручка выше прошлого года на 3,2 млрд руб. (+22%)
          </strong>
        </p>
        <p>
          Максимальное увеличение выручки – по основному виду деятельности –
          транспортировке газа (+3691 млн руб.) связано с увеличением объема
          транспортировки.
        </p>
        <p>
          Снижение выручки (-1041 млн руб.) по технологическому присоединению и
          выносу газопроводов из зоны застройки связано с изменением налогового
          законодательства. Часть доходов по данному виду деятельности,
          полученная от государственных заказчиков и не облагаемая налогом на
          добавленную стоимость, отражается в составе прочих доходов Общества.
          Совокупные доходы по технологическим присоединениям в отчетном периоде
          увеличились.
        </p>

        <Header3>4.3.2. ОПЕРАЦИОННЫЕРАСХОДЫ</Header3>

        <p>
          Структура себестоимости практически постоянна, типична для
          эксплуатирующей организации – с высокой долей затрат на оплату труда и
          амортизацию.
        </p>

        <MGCaption>Себестоимость, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
              <td>
                Доля в <br />
                выручке
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Амортизация активов</td>
              <td>{fInt(3163)}</td>
              <td>{fInt(3337)}</td>
              <td>{fInt(3469)}</td>
              <td>{fInt(4)}%</td>
              <td>{fInt(28)}%</td>
            </tr>

            <tr>
              <td>Оплата труда (с отчислениями)</td>
              <td>{fInt(4261)}</td>
              <td>{fInt(4246)}</td>
              <td>{fInt(4914)}</td>
              <td>{fInt(16)}%</td>
              <td>{fInt(40)}%</td>
            </tr>

            <tr>
              <td>Производственные затраты</td>
              <td>{fInt(2688)}</td>
              <td>{fInt(2298)}</td>
              <td>{fInt(2193)}</td>
              <td>({fInt(5)})%</td>
              <td>{fInt(18)}%</td>
            </tr>

            <tr>
              <td>Налоги</td>
              <td>{fInt(933)}</td>
              <td>{fInt(993)}</td>
              <td>{fInt(1063)}</td>
              <td>{fInt(7)}%</td>
              <td>{fInt(9)}%</td>
            </tr>

            <tr>
              <td>Прочие затраты</td>
              <td>{fInt(502)}</td>
              <td>{fInt(611)}</td>
              <td>{fInt(593)}</td>
              <td>({fInt(3)})%</td>
              <td>{fInt(5)}%</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Итого:</td>
              <td>{fInt(11_547)}</td>
              <td>{fInt(11_485)}</td>
              <td>{fInt(12_231)}</td>
              <td>{fInt(6)}%</td>
              <td>{fInt(100)}%</td>
            </tr>
          </tfoot>
        </table>

        <p>
          Увеличение расходов на оплату труда в составе себестоимости связано с
          пересмотром штатного расписания эксплуатационных подразделений и
          увеличением с октября 2021 года окладов в целях обеспечения
          конкуренто- способного уровня заработной платы по отрасли.
        </p>
        <p>
          Увеличение амортизации и налогов (начисленного налога на имущество) в
          себестоимости связано с ежегод- ным ростом стоимости основных фондов в
          результате их реконструкции и строительства.
        </p>

        <Header2>4.4. АНАЛИЗ СТРУКТУРЫ БАЛАНСА ОБЩЕСТВА</Header2>

        <table className="tBig">
          <thead>
            <tr>
              <td rowSpan={2}>Показатель</td>
              <td colSpan={2}>на 31.12.2019</td>
              <td colSpan={2}>на 31.12.2020</td>
              <td colSpan={2}>на 31.12.2021</td>
            </tr>
            <tr>
              <td>млн руб.</td>
              <td>
                Доля в <br />
                балансе
              </td>
              <td>млн руб.</td>
              <td>
                Доля в <br />
                балансе
              </td>
              <td>млн руб.</td>
              <td>
                Доля в <br />
                балансе
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="tBig--accent">
              <td>Внеоборотные активы</td>
              <td>{fInt(57_848)}</td>
              <td rowSpan={7}>{fInt(82)}%</td>
              <td>{fInt(64_392)}</td>
              <td rowSpan={7}>{fInt(79)}%</td>
              <td>{fInt(70_966)}</td>
              <td rowSpan={7}>{fInt(75)}%</td>
            </tr>

            <tr>
              <td>Нематериальные активы</td>
              <td>{fInt(14)}</td>
              <td>{fInt(15)}</td>
              <td>{fInt(14)}</td>
            </tr>

            <tr>
              <td>Основные средства</td>
              <td>{fInt(45_474)}</td>
              <td>{fInt(47_711)}</td>
              <td>{fInt(56_827)}</td>
            </tr>

            <tr>
              <td>Незавершенные капитальные вложения</td>
              <td>{fInt(11_739)}</td>
              <td>{fInt(16_016)}</td>
              <td>{fInt(13_359)}</td>
            </tr>

            <tr>
              <td>Финансовые вложения</td>
              <td>{fInt(1)}</td>
              <td>{fInt(1)}</td>
              <td>{fInt(1)}</td>
            </tr>

            <tr>
              <td>Отложенные налоговые активы</td>
              <td>{fInt(597)}</td>
              <td>{fInt(635)}</td>
              <td>{fInt(724)}</td>
            </tr>

            <tr>
              <td>Прочие внеоборотные активы</td>
              <td>{fInt(23)}</td>
              <td>{fInt(15)}</td>
              <td>{fInt(42)}</td>
            </tr>

            <tr className="tBig--accent">
              <td>Оборотные активы</td>
              <td>{fInt(12_471)}</td>
              <td rowSpan={5}>{fInt(18)}%</td>
              <td>{fInt(16_883)}</td>
              <td rowSpan={5}>{fInt(21)}%</td>
              <td>{fInt(23_450)}</td>
              <td rowSpan={5}>{fInt(25)}%</td>
            </tr>

            <tr>
              <td>Запасы</td>
              <td>{fInt(2096)}</td>
              <td>{fInt(1855)}</td>
              <td>{fInt(1955)}</td>
            </tr>

            <tr>
              <td>
                Налог на добавленную стоимость <br />
                по приобретенным ценностям
              </td>
              <td>{fInt(10)}</td>
              <td>{fInt(6)}</td>
              <td>{fInt(20)}</td>
            </tr>

            <tr>
              <td>Дебиторская задолженность</td>
              <td>{fInt(931)}</td>
              <td>{fInt(1563)}</td>
              <td>{fInt(1747)}</td>
            </tr>

            <tr>
              <td>Денежные средства</td>
              <td>{fInt(9425)}</td>
              <td>{fInt(13_446)}</td>
              <td>{fInt(19_696)}</td>
            </tr>

            <tr className="grayLight tBig--accent">
              <td>Итого актив баланса</td>
              <td>{fInt(70_320)}</td>
              <td>{fInt(100)}%</td>
              <td>{fInt(81_276)}</td>
              <td>{fInt(100)}%</td>
              <td>{fInt(94_416)}</td>
              <td>{fInt(100)}%</td>
            </tr>

            <tr className="tBig--accent">
              <td>Капитал</td>
              <td>{fInt(61_603)}</td>
              <td rowSpan={3}>{fInt(88)}%</td>
              <td>{fInt(68_146)}</td>
              <td rowSpan={3}>{fInt(84)}%</td>
              <td>{fInt(76_575)}</td>
              <td rowSpan={3}>{fInt(81)}%</td>
            </tr>

            <tr>
              <td>Уставный капитал</td>
              <td>{fInt(39_785)}</td>
              <td>{fInt(42_445)}</td>
              <td>{fInt(44_814)}</td>
            </tr>

            <tr>
              <td>Нераспределенная прибыль</td>
              <td>{fInt(21_766)}</td>
              <td>{fInt(25_650)}</td>
              <td>{fInt(31_710)}</td>
            </tr>

            <tr className="tBig--accent">
              <td>Долгосрочные обязательства</td>
              <td>{fInt(2202)}</td>
              <td rowSpan={3}>{fInt(3)}%</td>
              <td>{fInt(4989)}</td>
              <td rowSpan={3}>{fInt(6)}%</td>
              <td>{fInt(8955)}</td>
              <td rowSpan={3}>{fInt(9)}%</td>
            </tr>

            <tr>
              <td>Кредиторская задолженность</td>
              <td>{fInt(1329)}</td>
              <td>{fInt(3434)}</td>
              <td>{fInt(7367)}</td>
            </tr>

            <tr>
              <td>Доходы будущих периодов</td>
              <td>{fInt(725)}</td>
              <td>{fInt(1315)}</td>
              <td>{fInt(1179)}</td>
            </tr>

            <tr className="tBig--accent">
              <td>Краткосрочные обязательства</td>
              <td>{fInt(6514)}</td>
              <td rowSpan={6}>{fInt(9)}%</td>
              <td>{fInt(8141)}</td>
              <td rowSpan={6}>{fInt(10)}%</td>
              <td>{fInt(8886)}</td>
              <td rowSpan={6}>{fInt(9)}%</td>
            </tr>

            <tr>
              <td>Займы и кредиты</td>
              <td>—</td>
              <td>—</td>
              <td>—</td>
            </tr>

            <tr>
              <td>Кредиторская задолженность</td>
              <td>{fInt(4932)}</td>
              <td>{fInt(5542)}</td>
              <td>{fInt(6306)}</td>
            </tr>

            <tr>
              <td>Доходы будущих периодов</td>
              <td>{fInt(20)}</td>
              <td>{fInt(8)}</td>
              <td>{fInt(15)}</td>
            </tr>

            <tr>
              <td>Оценочные обязательства</td>
              <td>{fInt(360)}</td>
              <td>{fInt(435)}</td>
              <td>{fInt(531)}</td>
            </tr>

            <tr>
              <td>Прочие (целевое финансирование)</td>
              <td>{fInt(1201)}</td>
              <td>{fInt(2155)}</td>
              <td>{fInt(2035)}</td>
            </tr>

            <tr className="grayLight tBig--accent">
              <td>Итого актив баланса</td>
              <td>{fInt(70_320)}</td>
              <td>{fInt(100)}%</td>
              <td>{fInt(81_276)}</td>
              <td>{fInt(100)}%</td>
              <td>{fInt(94_416)}</td>
              <td>{fInt(100)}%</td>
            </tr>
          </tbody>
        </table>
        <p>В активе баланса:</p>
        <p todo="list?">
          Сохраняется высокая доля внеоборотных активов (более 75%). Основные
          средства увеличились на 9,1 млрд руб. за счет ввода в эксплуатацию
          объектов строительства, существенно превышающего амортизацию. Общая
          сумма инвестиций в основной капитал Общества превысила на 5,3%
          значение 2020 года и составила 9,8 млрд руб.
        </p>
        <p>
          На 4,4% увеличилась доля текущих активов за счет существенного роста
          остатка денежных средств (+6,25 млрд руб.) на счетах Общества.
        </p>
        <p>В пассиве баланса:</p>
        <p>
          Капитал Общества увеличился на 8,4 млрд руб. за счет проведения
          дополнительной эмиссии в размере 2368,7 млн руб. и роста
          нераспределенной прибыли.
        </p>
        <p>
          Увеличилась доля долгосрочных обязательств за счет увеличения суммы
          полученных авансов по долгосрочным договорам на технологическое
          присоединение и вынос газопроводов из зоны застройки.
        </p>

        <Header3>4.4.1. ДЕБИТОРСКАЯЗАДОЛЖЕННОСТЬ</Header3>

        <MGCaption>Дебиторская задолженность, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>на 31.12.2019</td>
              <td>на 31.12.2020</td>
              <td>на 31.12.2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="tBig--accent">
              <td>Дебиторская задолженность</td>
              <td>{fInt(931)}</td>
              <td>{fInt(1563)}</td>
              <td>{fInt(1747)}</td>
              <td>{fInt(12)}%</td>
            </tr>

            <tr>
              <td>расчеты с покупателями и заказчиками,</td>
              <td>{fInt(821)}</td>
              <td>{fInt(1127)}</td>
              <td>{fInt(1439)}</td>
              <td>{fInt(18)}%</td>
            </tr>

            <tr>
              <td>
                включая непредъявленную начисленную <br />
                выручку по переходящим договорам
              </td>
              <td>{fInt(178)}</td>
              <td>{fInt(274)}</td>
              <td>{fInt(441)}</td>
              <td></td>
            </tr>

            <tr>
              <td>авансы выданные</td>
              <td>{fInt(56)}</td>
              <td>{fInt(335)}</td>
              <td>{fInt(196)}</td>
              <td>({fInt(41)})%</td>
            </tr>

            <tr>
              <td>прочие дебиторы</td>
              <td>{fInt(53)}</td>
              <td>{fInt(102)}</td>
              <td>{fInt(112)}</td>
              <td>{fInt(10)}%</td>
            </tr>
          </tbody>
        </table>

        <p>
          Вся дебиторская задолженность Общества – текущая. Более 70% в общей
          сумме задолженности – задолжен- ность покупателей и заказчиков за
          оказанные услуги и выполненные работы, которая возникает как следствие
          предусмотренных договорами условий расчетов с потребителями.
        </p>

        <Header3>4.4.2. ДЕНЕЖНЫЕ СРЕДСТВА</Header3>
        <div className="Factoid text-xl mb-10">
          Остаток денежных средств увеличился на 6,25 млрд руб. (+46%)
        </div>

        <MGCaption>Денежные средства, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>на 31.12.2019</td>
              <td>на 31.12.2020</td>
              <td>на 31.12.2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="tBig--accent">
              <td>Всего остаток денежных средств</td>
              <td>{fInt(9425)}</td>
              <td>{fInt(13_446)}</td>
              <td>{fInt(19_696)}</td>
              <td>{fInt(46)}%</td>
            </tr>

            <tr>
              <td>
                Средства по операционной <br />
                деятельности, включая авансы по <br />
                договорам технологического <br />
                присоединения и выноса
              </td>
              <td>{fInt(4918)}</td>
              <td>{fInt(8798)}</td>
              <td>{fInt(15_345)}</td>
              <td>{fInt(74)}%</td>
            </tr>

            <tr>
              <td>
                Средства бюджетного <br />
                финансирования на спец. счетах <br />в Департаменте финансов
              </td>
              <td>{fInt(4507)}</td>
              <td>{fInt(4648)}</td>
              <td>{fInt(4351)}</td>
              <td>({fInt(6)})%</td>
            </tr>
          </tbody>
        </table>

        <p>
          Основной прирост остатка собственных денежных средств – за счет
          авансов, полученных по договорам технологического присоединения и
          выноса сетей из зоны застройки.
        </p>

        <Header3>4.4.3. КРЕДИТОРСКАЯ ЗАДОЛЖЕННОСТЬ</Header3>

        <MGCaption>Кредиторская задолженность, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>на 31.12.2019</td>
              <td>на 31.12.2020</td>
              <td>на 31.12.2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="tBig--accent">
              <td>Кредиторская задолженность долгосрочная</td>
              <td>{fInt(1329)}</td>
              <td>{fInt(3434)}</td>
              <td>{fInt(7367)}</td>
              <td>{fInt(115)}%</td>
            </tr>

            <tr>
              <td>
                расчеты с поставщиками и подрядчиками (гарантийные
                обязательства)
              </td>
              <td>{fInt(1)}</td>
              <td>{fInt(9)}</td>
              <td>{fInt(9)}</td>
              <td>({fInt(2)})%</td>
            </tr>

            <tr>
              <td>авансы полученные</td>
              <td>{fInt(1328)}</td>
              <td>{fInt(3425)}</td>
              <td>{fInt(7358)}</td>
              <td>{fInt(115)}%</td>
            </tr>

            <tr className="tBig--accent">
              <td>Кредиторская задолженность текущая</td>
              <td>{fInt(4932)}</td>
              <td>{fInt(5542)}</td>
              <td>{fInt(6306)}</td>
              <td>{fInt(14)}%</td>
            </tr>

            <tr>
              <td>расчеты с поставщиками и подрядчиками</td>
              <td>{fInt(710)}</td>
              <td>{fInt(1102)}</td>
              <td>{fInt(1000)}</td>
              <td>({fInt(9)})%</td>
            </tr>

            <tr>
              <td>авансы полученные</td>
              <td>{fInt(2896)}</td>
              <td>{fInt(2988)}</td>
              <td>{fInt(2858)}</td>
              <td>({fInt(4)})%</td>
            </tr>

            <tr>
              <td>расчеты по налогам и сборам</td>
              <td>{fInt(851)}</td>
              <td>{fInt(831)}</td>
              <td>{fInt(1339)}</td>
              <td>{fInt(61)}%</td>
            </tr>

            <tr>
              <td>задолженность перед персоналом</td>
              <td>{fInt(135)}</td>
              <td>{fInt(152)}</td>
              <td>{fInt(173)}</td>
              <td>{fInt(14)}%</td>
            </tr>

            <tr>
              <td>задолженность перед внебюджетными фондами</td>
              <td>{fInt(139)}</td>
              <td>{fInt(147)}</td>
              <td>{fInt(199)}</td>
              <td>{fInt(36)}%</td>
            </tr>

            <tr>
              <td>прочие кредиторы</td>
              <td>{fInt(202)}</td>
              <td>{fInt(323)}</td>
              <td>{fInt(736)}</td>
              <td>{fInt(128)}%</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Кредиторская задолженность, итого</td>
              <td>{fInt(6261)}</td>
              <td>{fInt(8976)}</td>
              <td>{fInt(13_673)}</td>
              <td>{fInt(52)}%</td>
            </tr>
          </tfoot>
        </table>

        <p>Общество не привлекает заемных средств.</p>

        <p>
          Авансы, полученные со сроком погашения более 12 мес., с отчетной даты
          увеличились на 3,9 млрд руб. Заключены новые крупные договоры на
          технологическое присоединение, для которых характерен авансовый
          порядок расчетов с длительным сроком реализации проектов.
        </p>

        <p>
          Увеличение задолженности на конец года по налогам и сборам происходит
          по причине увеличения выручки и показателя прибыли в 2021 году. Все
          налоги уплачены Обществом в установленные действующим
          законодательством сроки.
        </p>

        <Header2>4.5. НАЛОГИ</Header2>

        <div className="Factoid text-xl mb-10">
          6,3 млрд руб. – налоги и взносы
          <br />
          Более 35% от выручки – налоговая нагрузка
        </div>

        <MGCaption>Налоги, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="tBig--accent">
              <td>Налоги начисленные, всего, в т.ч.</td>
              <td>{fInt(3447)}</td>
              <td>{fInt(3760)}</td>
              <td>{fInt(4770)}</td>
              <td>{fInt(27)}%</td>
            </tr>

            <tr>
              <td>налог на прибыль</td>
              <td>{fInt(535)}</td>
              <td>{fInt(819)}</td>
              <td>{fInt(1501)}</td>
              <td>{fInt(83)}%</td>
            </tr>

            <tr>
              <td>налог на добавленную стоимость (НДС)</td>
              <td>{fInt(1368)}</td>
              <td>{fInt(1313)}</td>
              <td>{fInt(1487)}</td>
              <td>{fInt(13)}%</td>
            </tr>

            <tr>
              <td>налог на имущество</td>
              <td>{fInt(917)}</td>
              <td>{fInt(976)}</td>
              <td>{fInt(1048)}</td>
              <td>{fInt(7)}%</td>
            </tr>

            <tr>
              <td>налог на землю</td>
              <td>{fInt(14)}</td>
              <td>{fInt(14)}</td>
              <td>{fInt(12)}</td>
              <td>({fInt(20)})%</td>
            </tr>

            <tr>
              <td>транспортный налог</td>
              <td>{fInt(5)}</td>
              <td>{fInt(5)}</td>
              <td>{fInt(6)}</td>
              <td>{fInt(7)}%</td>
            </tr>

            <tr>
              <td>налог на доходы физических лиц (НДФЛ)</td>
              <td>{fInt(608)}</td>
              <td>{fInt(631)}</td>
              <td>{fInt(716)}</td>
              <td>{fInt(13)}%</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Страховые взносы</td>
              <td>{fInt(1327)}</td>
              <td>{fInt(1347)}</td>
              <td>{fInt(1548)}</td>
              <td>{fInt(15)}%</td>
            </tr>
            <tr>
              <td>Налоговая нагрузка, всего</td>
              <td>{fInt(4774)}</td>
              <td>{fInt(5107)}</td>
              <td>{fInt(6317)}</td>
              <td>{fInt(24)}%</td>
            </tr>
          </tfoot>
        </table>

        <p>
          В том числе <strong>в бюджет города Москвы</strong> начислено и
          уплачено налогов в 2021 году <strong>2,34 млрд руб.</strong>
        </p>

        <Header2>4.6. АНАЛИЗ ИНДИКАТОРОВ ФИНАНСОВОГО СОСТОЯНИЯ</Header2>

        <MGCaption>Чистые активы, млн руб.</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>
                откл. <br />
                2021/2020
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Чистые активы Общества</td>
              <td>{fInt(62_349)}</td>
              <td>{fInt(69_468)}</td>
              <td>{fInt(77_768)}</td>
              <td>{fInt(12)}%</td>
            </tr>

            <tr>
              <td>Отношение чистые активы/уставный капитал</td>
              <td>{fInt(157)}%</td>
              <td>{fInt(164)}%</td>
              <td>{fInt(174)}%</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <p>
          Чистые активы по состоянию на 31.12.2021 на 74% превышают уставный
          капитал. Такое соотношение положи- тельно характеризует финансовое
          положение Общества, полностью удовлетворяя требованиям нормативных
          актов к величине чистых активов.
        </p>

        <MGCaption>Показатели рентабельности</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>Формула расчета</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Рентабельность продаж</td>
              <td>Прибыль от продаж/Выручка</td>
              <td>{fInt(17)}%</td>
              <td>{fInt(20)}%</td>
              <td>{fInt(31)}%</td>
            </tr>

            <tr>
              <td>Рентабельность по чистой прибыли</td>
              <td>Чистая прибыль/Выручка</td>
              <td>{fInt(16)}%</td>
              <td>{fInt(27)}%</td>
              <td>{fInt(34)}%</td>
            </tr>

            <tr>
              <td>Рентабельность активов</td>
              <td>Чистая прибыль/Стоимость активов</td>
              <td>{fInt(3)}%</td>
              <td>{fInt(5)}%</td>
              <td>{fInt(6)}%</td>
            </tr>

            <tr>
              <td>Рентабельность собственного капитала</td>
              <td>Чистая прибыль/Капитал</td>
              <td>{fInt(4)}%</td>
              <td>{fInt(6)}%</td>
              <td>{fInt(8)}%</td>
            </tr>
          </tbody>
        </table>

        <p>
          В 2021 году отмечается существенный рост показателей рентабельности.
        </p>

        <MGCaption>Показатели ликвидности и финансовой устойчивости</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>Формула расчета</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>Нормативное значение</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Коэффициент текущей ликвидности</td>
              <td>
                Текущие активы / Текущие обязательства за минусом резервов и
                доходов будущих периодов
              </td>
              <td>{fFloat(2.0, 1)}</td>
              <td>{fFloat(2.2, 1)}</td>
              <td>{fFloat(2.8, 1)}</td>
              <td>{fFloat(2.0, 1)}</td>
            </tr>

            <tr>
              <td>Коэффициент автономии (финансовой независимости)</td>
              <td>Капитал / Cальдо баланса</td>
              <td>{fFloat(0.9, 1)}</td>
              <td>{fFloat(0.8, 1)}</td>
              <td>{fFloat(0.8, 1)}</td>
              <td>{fFloat(0.5, 1)}</td>
            </tr>

            <tr>
              <td>
                Коэффициент обеспеченности собственными источниками
                финансирования
              </td>
              <td>(Капитал–Внеоборотные активы) / Текущие активы</td>
              <td>{fFloat(0.3, 1)}</td>
              <td>{fFloat(0.2, 1)}</td>
              <td>{fFloat(0.2, 1)}</td>
              <td>{fFloat(0.1, 1)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          Финансовое состояние АО «МОСГАЗ» является стабильным. Значения
          основных финансовых показателей, характеризующие ликвидность и
          финансовую устойчивость, выше нормативных.
        </p>

        <MGCaption>Показатели деловой активности</MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>Формула расчета</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Период оборачиваемости денежных средств, дней</td>
              <td>365/(выручка/средний остаток денежных средств)</td>
              <td>{fInt(188)}</td>
              <td>{fInt(290)}</td>
              <td>{fInt(343)}</td>
            </tr>

            <tr>
              <td>Период оборачиваемости дебиторской задолженности, дней</td>
              <td>365/(выручка/средний остаток дебиторской задолженности)</td>
              <td>{fInt(27)}</td>
              <td>{fInt(32)}</td>
              <td>{fInt(34)}</td>
            </tr>

            <tr>
              <td>Период оборачиваемости кредиторской задолженности, дней</td>
              <td>365/(выручка/средний остаток кредиторской задолженности)</td>
              <td>{fInt(146)}</td>
              <td>{fInt(193)}</td>
              <td>{fInt(235)}</td>
            </tr>

            <tr>
              <td>
                Период оборачиваемости товарно- материальных запасов, дней
              </td>
              <td>365/(выручка/среднее значение запасов)</td>
              <td>{fInt(60)}</td>
              <td>{fInt(50)}</td>
              <td>{fInt(39)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          Показатели оборачиваемости принимают за трехлетний период
          приблизительно стабильные значения.
        </p>

        <p>
          Увеличение периода оборачиваемости денежных средств связано с ростом
          остатков на счетах, в первую очередь, за счет увеличения сумм
          полученных долгосрочных авансов по договорам на технологическое
          присоединение и вынос сетей из зоны застройки.
        </p>

        <p>
          Исходя из анализа дебиторской задолженности крупнейших дебиторов,
          задолженность является текущей и погашена контрагентами в срок в
          соответствии с условиями договоров.
        </p>

        <p>
          Период оборота кредиторской задолженности вырос по причине роста сумм
          долгосрочных полученных авансов.
        </p>

        <p>Период оборачиваемости запасов снизился.</p>
        <NaviNext
          next="/part-5"
          title="РАЗДЕЛ 5. КОРПОРАТИВНОЕ УПРАВЛЕНИЕ"
        ></NaviNext>
      </TextBlock>
    </Tail>
  );
};

export default Part4;

export const Head = () => (
  <Seo title="РАЗДЕЛ 4. ОСНОВНЫЕ ПОКАЗАТЕЛИ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ" />
);
